import React from 'react';
import { Helmet } from 'react-helmet-async';

const Seo = ({ title, description, image, keywords, fullUrl, domain })  => {
	return (
		<Helmet>
			<title>{title}</title>
			<meta name="description" content={description} />
			<meta name="keywords" content={keywords} />
			{/* Facebook Tags */}
			<meta property="og:url" content={fullUrl} />
			<meta property="og:type" content="website" />
			<meta property="og:title" content={title} />
			<meta property="og:description" content={description} />
			<meta property="og:image" content={image} />
			<meta property="og:site_name" content="tbrantleyii" />
			{/* Twitter Tags */}
			<meta name="twitter:card" content="summary_large_image" />
			<meta name="twitter:site" content="@thecompanydream" />
			<meta name="twitter:creator" content="@thecompanydream" />
			<meta name="twitter:title" content={title} />
			<meta name="twitter:description" content={description} />
			<meta name="twitter:image" content={image} />
			<meta name="twitter:domain" content={domain} />
			<meta name="twitter:url" content={fullUrl} />
			<meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
		</Helmet>
	);
}

export default Seo;