import { createContext } from 'react'

const BookObject = {
    items: [],
    totalItems: 1,
    startIndex: 0,
    maxResults: 21,
    search: "song of ice and fire"
}

export default createContext(BookObject)