import React from 'react'
import { Link } from "react-router-dom"
import "./styles/base.scss"

const Layout = ({ children }) => (
	<section className="container">
		<header>
			<nav className="navbar" role="navigation" aria-label="main navigation">
				<div className="navbar-start">
					<Link className="navbar-item" to="/">
						<h2 className="title">Qohelet 408</h2>
					</Link>
				</div>

				<div className="navbar-end">
					<div className="navbar-item">
						<Link className=" is-primary" to="/about">
							<h2 className="title">About</h2>
						</Link>
					</div>
				</div>
			</nav>
		</header>
		{children}
	</section>
)

export default Layout