import React, { useEffect, useContext, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { BookContext, Seo } from './';
import './styles/detail.scss';

// Helper function to get the best image
const getBestImage = (imageLinks) => {
  if (!imageLinks) return null;
  return imageLinks.medium || imageLinks.large || imageLinks.small || imageLinks.thumbnail || imageLinks.smallThumbnail || null;
};

const Detail = () => {
  let { bookId } = useParams();
  const { books, setBooks } = useContext(BookContext);
  const [book, setBook] = useState(null);
  const apiKey = process.env.REACT_APP_API_KEY;

  const fetchBookById = async (id) => {
    const response = await fetch(`https://www.googleapis.com/books/v1/volumes/${id}?key=${apiKey}`);
    const book = await response.json();
    setBook(book);
    if (books.items.length !== 0) {
      const library = books.items.filter((item => item.id !== bookId));
      setBooks({ ...books, items: [...library, book] });
    } else {
      setBooks({ ...books, items: [book] });
    }
  };

  useEffect(() => {
    if (bookId && !book) {
      fetchBookById(bookId);
    }
  }, [bookId, books, apiKey, book, setBooks]);

  if (!book) {
    return (
      <section className="section">
        <h1 className="title">Book not found</h1>
        <Link className="button" to="/">Back to Home</Link>
      </section>
    );
  }

  const {
    title,
    subtitle,
    authors,
    description,
    publisher,
    publishedDate,
    pageCount,
    categories,
    imageLinks,
    infoLink,
    language,
    printType,
    averageRating,
    ratingsCount,
    maturityRating,
    industryIdentifiers,
  } = book.volumeInfo;

  const bookImage = getBestImage(imageLinks); // Use helper function here

  return (
    <article className="section">
      <Seo
        title={title}
        description={`A detailed look inside ${title}`}
        image={bookImage || `https://${process.env.REACT_APP_DOMAIN}/logo192.png`} // Fallback to a default image if none available
        keywords=""
        fullUrl={`https://${process.env.REACT_APP_DOMAIN}`}
        domain={process.env.REACT_APP_DOMAIN}
      />
      <main className="columns is-centered">
        <div className="column is-full">
          <div className="media">
            <div className="media-left">
              {bookImage && (
                <figure className="image">
                  <img src={bookImage} alt={`${title} cover`} />
                </figure>
              )}
            </div>
            <div className="media-content">
              <h1 className="title is-3">{title}</h1>
              {subtitle && <h2 className="subtitle is-4">{subtitle}</h2>}
              <p><strong>Authors:</strong> {authors?.join(', ') || 'Unknown'}</p>
              <p><strong>Publisher:</strong> {publisher || 'Unknown'}</p>
              <p><strong>Published Date:</strong> {publishedDate || 'Unknown'}</p>
              <p><strong>Page Count:</strong> {pageCount || 'Unknown'}</p>
              <p><strong>Categories:</strong> {categories?.join(', ') || 'Unknown'}</p>
              <p><strong>Language:</strong> {language?.toUpperCase() || 'Unknown'}</p>
              <p><strong>Print Type:</strong> {printType || 'Unknown'}</p>
              <p><strong>Maturity Rating:</strong> {maturityRating || 'Unknown'}</p>
              <p><strong>Average Rating:</strong> {averageRating || 'No ratings'}</p>
              <p><strong>Ratings Count:</strong> {ratingsCount || 0}</p>
              <p className="description"><strong>Description:</strong> <span dangerouslySetInnerHTML={{ __html: description || 'No description available' }}></span></p>
              <p><strong>Industry Identifiers:</strong></p>
              <ul>
                {industryIdentifiers?.map((identifier) => (
                  <li key={identifier.identifier}>{identifier.type}: {identifier.identifier}</li>
                )) || <li>Not available</li>}
              </ul>
              <p>
                <strong>More Info:</strong>{' '}
                <a href={infoLink} target="_blank" rel="noopener noreferrer">
                  Google Books
                </a>
              </p>
            </div>
          </div>
          <div className="buttons">
            <Link className="button is-link" to="/">Back to Home</Link>
          </div>
        </div>
      </main>
    </article>
  );
};

export default Detail;
