import React, { Suspense, useState, useMemo} from "react";
import { Route } from "react-router-dom";
import { Layout, BookContext } from "./components";

const Home = React.lazy(() => import('./components/home'));
const About = React.lazy(() => import('./components/about'));
const Detail = React.lazy(() => import('./components/detail'));

const App = () => {
  const defaultBookContext = {
    items: [],
    totalItems: 1,
    startIndex: 0,
    maxResults: 21,
    search: "",
  };
  const [books, setBooks] = useState(defaultBookContext)
  const contextMemo = useMemo(
    () => ({books, setBooks}),
    [books]
  )

  return (
    <BookContext.Provider value={contextMemo}>
      <Layout>
        <Suspense fallback={<div>Loading ...</div>}>
          <Route exact path="/" component={Home} />
          <Route exact path="/about" component={About} />
          <Route path="/detail/:bookId" component={Detail} />
        </Suspense>
      </Layout>
    </BookContext.Provider>
  );
};

export default App;
