import React, { useContext, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { BookContext, Seo } from './';
import './styles/home.scss';

const Home = () => {
  const { books, setBooks } = useContext(BookContext);
  const apiKey = process.env.REACT_APP_API_KEY;

  // Debounce function to limit the fetch requests
  const debounce = (func, delay) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  // Wrap fetchData in debounce
  const fetchData = useCallback(
    debounce(() => {
      fetch(
        `https://www.googleapis.com/books/v1/volumes?q=${encodeURIComponent(
          books.search
        )}&maxResults=${books.maxResults}&startIndex=${books.startIndex}&key=${apiKey}`
      )
        .then((res) => res.json())
        .then((data) => {
          if (books.startIndex === 0) {
            setBooks((prevBooks) => ({
              ...prevBooks,
              items: data.items,
              totalItems: data.totalItems,
            }));
          } else if (books.startIndex > 0 && data.items) {
            setBooks((prevBooks) => ({
              ...prevBooks,
              items: [...books.items, ...data.items],
              totalItems: data.totalItems,
            }));
          }
        });
    }, 1000), // Debounce the API call with a delay of 300ms
    [books.search, books.maxResults, books.startIndex, apiKey, setBooks]
  );

  const handleSearch = (e) => {
    setBooks((prevBooks) => ({ ...prevBooks, startIndex: 0, search: e.target.value }));
    if (e.target.value.length > 0) {
      console.log(e.target.value)
      fetchData()
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop >=
        document.documentElement.offsetHeight - 100 && books.items.length > 0
      ) {
        if (books.totalItems > books.startIndex + books.maxResults ) {
          setBooks((prevBooks) => ({
            ...prevBooks,
            startIndex: prevBooks.startIndex + prevBooks.maxResults,
          }));
          fetchData()
        }
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [books.totalItems, books.startIndex, books.maxResults, setBooks, fetchData]);

  return (
    <main className="section is-centered">
      <Seo
        title={"Home"}
        description={"home page for qohelet408 where we search for books."}
        image={"https://qohelet408.com/logo192.png"}
        keywords={""}
        fullUrl={"https://qohelet408.com"}
        domain={"qohelet408.com"}
      />
      <header className="field has-addon">
        <nav className="control is-full">
          <input
            className="input is-medium"
            type="text"
            placeholder="Search for books..."
            value={books.search}
            onChange={handleSearch}
          />
        </nav>
      </header>

      <ul className="booklist">
        {books.items &&
          books.items.map((book, idx) => (
            <li className="book-profile" key={`${book.id}-${book.etag}`}>
              <Link to={`/detail/${book.id}`} className="book-link">
                <article className="box book-card">
                  <div className="book-image">
                    {book.volumeInfo.imageLinks && (
                      <img
                        src={book.volumeInfo.imageLinks.thumbnail}
                        alt={`${book.volumeInfo.title} cover`}
                      />
                    )}
                  </div>
                  <div className="book-info">
                    <h2 className="title is-4">{book.volumeInfo.title}</h2>
                    <p className="subtitle is-6">
                      Authors: {book.volumeInfo.authors?.join(', ')}
                    </p>
                    <p>Publisher: {book.volumeInfo.publisher}</p>
                    <p>Published Date: {book.volumeInfo.publishedDate}</p>
                    <p>Page Count: {book.volumeInfo.pageCount}</p>
                    <p>
                      Description: {book.volumeInfo.description
                        ? `${book.volumeInfo.description.substring(0, 100)}...`
                        : 'No description available'}
                    </p>
                  </div>
                </article>
              </Link>
            </li>
          ))}
      </ul>
      <footer>
        <h2 className="title is-4">Congrats, You hit the end of the page now search <a href="#">something else</a> </h2>
      </footer>
    </main>
  );
};

export default Home;
